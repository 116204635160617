<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcIOCSummaries }}</h1>
    </header>
    <section class="container bg-block">
      <b-form-group class="form-element">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcRegion }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="regions" v-model="region.org_key" :plain="true"
          @change="regionChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group>
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcReports }}</strong>
        </div>
        <b-form-select v-model="report.key" :options="reportOptionsForIOCSummaries" :plain="true"
          @change="reportChange($event)">
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcYear }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="reportYears" :plain="true" v-model="reportYear"
          @change="reportYearChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(ReportDetail, index) in this.reportUrls" :key="index">
          <b-link target="_blank" :href="ReportDetail.value"><strong>{{ ReportDetail.text }}</strong></b-link>
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick">{{
        translations.components['report-parameters'].tcCancel
        }}</b-button>
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { translationMixin } from '../../mixins/translationMixin'

export default {
  name: 'ioc-summaries',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': [],
        },
      },
      title: 'ICC Summaries',
      reportOptionsForIOCSummaries: [],
      region: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      regions: [
        {
          text: 'Select a Region',
          value: constantData.empty_guid,
        },
      ],
      report: {
        key: '',
        value: null,
      },
      reportUrls: [],
      reportUrlsData: {
        region: null,
        report: null,
        reportYear: null,
      },
      reportYear: null,
      reportYears: [],
      reportYearsData: {
        region: null,
        report: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getRegions: 'secureReport/getRegions',
      getRegionUrls: 'secureReport/getRegionUrls',
      getRegionYears: 'secureReport/getRegionYears',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      await this.getRegions().then(() => {
        this.regions = this.regionDetails.map((item) => {
          return { text: item.org_name, value: item.org_key }
        })
      })
      this.regions.unshift({
        text: this.translations.components['report-parameters'].tcSelectARegion,
        value: constantData.empty_guid,
      })
      this.region.org_key = constantData.empty_guid
      this.report.key = ''
      this.reportYear = ''
      this.setLoadingStatus(false)
    },
    async regionChange(evt) {
      this.reportUrls = []
    },
    async reportChange(evt) {
      this.setLoadingStatus(true)
      this.reportYearsData.region = this.regionDetails.find((x) => x.org_key === this.region.org_key).lvl_code
      this.reportYearsData.report = this.report.key
      await this.getRegionYears(this.reportYearsData).then(() => {
        this.reportYears = this.regionYearDetails.map((item) => {
          return { text: item, value: item }
        })
      })
      this.reportYears.unshift({ text: this.translations.components['report-parameters'].tcSelectAYear, value: '' })
      this.reportYear = ''
      this.reportUrls = []
      this.setLoadingStatus(false)
    },
    async reportYearChange(evt) {
      this.reportUrls = []
      this.setLoadingStatus(true)
      this.reportUrlsData.region = this.regionDetails.find((x) => x.org_key === this.region.org_key).lvl_code
      this.reportUrlsData.report = this.report.key
      this.reportUrlsData.reportYear = evt
      this.reportUrls = await this.availableReportList
      this.setLoadingStatus(false)
    },
    handleCancelClick() {
       this.$router.push({ path: `/reports/report-center` })
    },
    setYearPlaceholder() {
      this.reportYears.push({ text: this.translations.components['report-parameters'].tcSelectAYear, value: '' })
    },
    setReportOptions() {
      let tempOptions = [
        { value: '', text:  this.translations.components['report-parameters'].tcSelectAReport },
        { value: 'IARM', text: this.translations.components['report-parameters'].tcIOCRegionSummaryMembership },
        { value: 'IARP', text: this.translations.components['report-parameters'].tcIOCRegionSummaryPlacements },
        { value: 'IARR', text: this.translations.components['report-parameters'].tcIOCRegionSummaryReceipts },
        { value: 'IARC', text: this.translations.components['report-parameters'].tcIOCRegionSummaryCombined },
        { value: 'ICSM', text: this.translations.components['report-parameters'].tcIOCCountrySummaryMembership },
        { value: 'ICSP', text: this.translations.components['report-parameters'].tcIOCCountrySummaryPlacements },
        { value: 'ICSR', text: this.translations.components['report-parameters'].tcIOCCountrySummaryReceipts },
        { value: 'ICSC', text: this.translations.components['report-parameters'].tcIOCTotalSummary },
      ]
      tempOptions.forEach(element => this.reportOptionsForIOCSummaries.push(element));
    }
  },

  async created() {
    await this.getPseudoComponentTranslations('report-parameters').then((results) => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
      this.setYearPlaceholder()
      this.setReportOptions()
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      regionDetails: 'secureReport/regions',
      regionReportDetails: 'secureReport/regionReportUrls',
      regionYearDetails: 'secureReport/regionReportYears',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
    }),
    async availableReportList() {
      await this.getRegionUrls(this.reportUrlsData)
      return await this.regionReportDetails.map((item) => {
        return { text: item.Name, value: item.URL }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox+div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
